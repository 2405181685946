import React from "react";
import workImage1 from "../image/logo1.png";
import workImage2 from "../image/NT.png";
import workImage3 from "../image/logo2.jpg";
import workImage4 from "../image/logo3.png";

import "./Experience.css";

export const Experience = () => {
  const experiences = [
    {
      company: "A Round Entertainment",
      title: "Software Engineer Intern",
      location: "San Jose, CA, US",
      time: "Sept, 2024 - Present",
      description:
        "Redesigned 15+ backend APIs and data models, including user management system and match system to align with business model. \n Integrated google cloud platform to enhance scalability. Enhanced the system's payment capabilities by integrating Stripe API for payment processing and setting up Webhook to handle real-time transaction updates and events \n Collaborated with the UI/UX team to integrate 5+ front-end features, including in app promotion system, membership system and user preferences system, optimizing user engagement and retention \n Assisted in drafting privacy policies and documentation to ensure compliance with GDPR and U.S. law. Addressed potential legal loopholes and mitigated submission-related issues",
      image: workImage1,
    },
    {
      company: "Network Tree",
      title: "Software Engineer Intern",
      location: "New York, NY, US",
      time: "May, 2024 - Sept, 2024",
      description:
        "Designed the backend architecture to organize user contacts into distinct community groups and manage personal contacts, allowing for streamlined contact management and improved user interactions within each group \n Implemented front-end features for both iOS and Android platforms, ensuring a consistent user experience across devices. This cross-platform consistency contributed to a increase in user retention \n Integrated Auth0 for secure authentication with MFA for high-risk actions, streamlining logins and enhancing security. Added session management to reduce login frequency and improve user convenience \n Leveraged AWS EventBridge Scheduler to automate task scheduling and deployed services on Amazon ECS, improving resource efficiency and enabling seamless scalability for backend processes",
      image: workImage2,
    },

    {
      company: "Hangzhou Garden Landscape Society",
      title: "Web Developer Intern",
      location: "Hangzhou, ZJ, CN",
      time: "Jan, 2024 - Jun, 2024",
      description:
        "Designed and implemented RESTful APIs for local gardens in Hangzhou. Created the first website exclusively dedicated to local lotus garden, served 2,000 viewers since inception.",
      skills: ["Node.js", "Express", "MongoDB", "API Design"],
      image: workImage3,
    },
    {
      company: "Rise2Gether",
      title: "Web Developer Volunteer",
      location: "Seattle, Washington, US",
      time: "Jun, 2024 - Present",
      description:
        "Partnered with a team of developers and designers to update the website, adding necessary sections such as event schedules, blog posts, and member list, ensuring the content remains current and engaging.",
      image: workImage4,
    },
  ];

  return (
    <section id="work-experience">
      <h3>EXPERIENCE</h3>

      <div className="experience-container">
        {experiences.map((experience, index) => (
          <div key={index} className="experience-item">
            <img
              src={experience.image}
              alt={experience.company}
              className="experience-image"
            />
            <div className="experience-text">
              <h2>{experience.company}</h2>
              <h4>{experience.title}</h4>
              <h5>{experience.location}</h5>
              <h5>{experience.time}</h5>
              <p>{experience.description}</p>
              {experience.skills && (
                <>
                  <p>Skills: {experience.skills.join(", ")}</p>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
